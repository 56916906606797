import { ReactNode, useEffect, useState } from 'react';
import clsx from 'clsx';
import Button from '../../components/ui/Button';
import HomeIcon from '../../assets/icons/home.svg?react';
import XCircleIcon from '../../assets/icons/x-circle.svg?react';
import CheckIcon from '../../assets/icons/check.svg?react';
import VDotsIcon from '../../assets/icons/v-dots.svg?react';
import EditIcon from '../../assets/icons/edit.svg?react';
import RefreshIcon from '../../assets/icons/refresh.svg?react';
import VideoIcon from '../../assets/icons/video.svg?react';
import NoVideoIcon from '../../assets/icons/no_video.svg?react';
import NoHDIcon from '../../assets/icons/no_hd.svg?react';
import No16x9Icon from '../../assets/icons/no_16x9.svg?react';
import SaveIcon from '../../assets/icons/save.svg?react';
import ExternalIcon from '../../assets/icons/external-link.svg?react';
import {
  approveOrder,
  cancelOrderDelivery,
  getOrder,
  OnUpdateOrderMutations,
  onUpdateOrderSubscription,
  rejectOrder,
  restartOrderDelivery,
  updateOrder
} from '../../features/orders/ordersApi';
import { useParams } from 'react-router-dom';
import Skeleton from '../../components/ui/Skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { setOrder, updateOrderDeliveryStatus } from '../../features/orders/ordersSlice';
import { RootState } from '../../app/store';
import { ListItem } from '../../components/ui/List';
import Breadcrumbs, { BreadcrumbsItem } from '../../components/ui/Breadcrumbs';
import { formatDateTime } from '../../common/dates';
import {
  Title,
  UpdateOrderInput,
  ContentErrorType,
  ORDER_LICENSE_STATUS_METADATA,
  ORDER_DELIVERY_STATUS_METADATA,
  OrderTitleDelivery
} from '../../features/orders/types';
import Modal from '../../components/ui/Modal';
import { ALL_SYSTEMS, ifeSystemSummaryLabel } from '../../common/target_systems';
import { IfeFormat, IfeSystem } from '../../features/organizations/types';
import { useUserContext } from '../../common/providers/UserProvider';
import { Helmet } from 'react-helmet-async';
import Input from '../../components/ui/Input';
import { Controller, FieldError, useForm } from 'react-hook-form';
import Tag from '../../components/ui/Tag';
import { useAppSelector } from '../../app/hooks';
import { selectUserGroups } from '../../features/userGroups/userGroupsSlice';
import { languages } from '../../common/languages';
import { TitleLabel } from '../../components/shared/TitleLabel';
import { TitleDeliveryActionSet } from '../../components/order-details/TitleDeliveryActionSet';
import FormItem from '../../components/ui/FormItem';
import { OrderHistory } from '../../components/order-details/OrderHistory';
import { Popover, PopoverDropdownContent, PopoverTrigger } from '../../components/ui/Popover';
import Select from '../../components/ui/Select';
import { brandOptions } from '../../common/brands';
import {
  DeliveryItemStatusLabels,
  DerivedOrderDeliveryStatusLabels,
  OrderLicenseStatusLabels
} from '../../common/types';
import { GraphqlSubscriptionMessage } from '@aws-amplify/api-graphql';
import { diffValues } from '../../common/utils';
import { Tooltip } from '../../components/ui/Tooltip';
import { CONTENT_PORTAL_URL } from '../../common/constants';
import { createUpdatedOrder } from '../../features/orders/utils';

export default function OrderDetailPage() {
  const dispatch = useDispatch();
  const currentUserGroups = useAppSelector(selectUserGroups);
  const { orderId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [selectedDelivery, setSelectedDelivery] = useState<OrderTitleDelivery>();
  const [dotMenuOpen, setDotMenuOpen] = useState(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const { currentOrder } = useSelector((state: RootState) => state.orders);
  const { userOrgsMap } = useUserContext();

  const { register, handleSubmit, control, formState, reset, setValue } = useForm<UpdateOrderInput>(
    {
      defaultValues: {
        externalId: '',
        brand: ''
      }
    }
  );

  const onSubmit = async (values: UpdateOrderInput) => {
    setIsSubmitting(true);
    if (orderId && currentOrder) updateOrder(orderId, diffValues(values, currentOrder));
    else console.error('No order to update');
    setIsSubmitting(false);
    setShowEdit(false);
  };

  useEffect(() => {
    let updateOrderSubscription: { unsubscribe: () => void };
    if (currentOrder?.organization && currentOrder?._id) {
      updateOrderSubscription = onUpdateOrderSubscription(
        currentOrder.organization,
        currentOrder._id,
        (result: GraphqlSubscriptionMessage<OnUpdateOrderMutations>) => {
          const onUpdateOrder = result.data?.onUpdateOrder;
          console.log('update order subscription: ', onUpdateOrder);
          console.log('currentOrder: ', currentOrder);
          if (onUpdateOrder) {
            dispatch(setOrder(createUpdatedOrder(onUpdateOrder, currentOrder)));
          }
        }
      );
    }

    return () => {
      // unsubscribe from updates to further updates for this order
      if (updateOrderSubscription) {
        updateOrderSubscription.unsubscribe();
      }
    };
  }, [currentOrder]);

  const breadcrumbItems: BreadcrumbsItem[] = [
    {
      link: '/',
      icon: <HomeIcon />
    },
    {
      title: 'Orders',
      link: '/orders'
    },
    {
      title: 'Order Details'
    }
  ];

  const getData = () => {
    if (orderId) {
      getOrder(orderId, (result) => {
        setIsLoading(false);
        dispatch(setOrder(result));
        setValue('externalId', result?.externalId || '');
        setValue('brand', result?.brand || '');
      });
    }
  };

  useEffect(() => {
    if (orderId) {
      setIsLoading(true);
      getData();
    }
  }, [orderId]);

  const getIfeSystem = (delivery: OrderTitleDelivery | undefined): IfeSystem | undefined => {
    if (!delivery) return undefined;
    const system: IfeSystem | undefined = ALL_SYSTEMS.find((s) => s.item_id === delivery.ifeSystem);
    return system;
  };

  const getIfeFormat = (delivery: OrderTitleDelivery | undefined): IfeFormat | undefined => {
    if (!delivery) return undefined;
    const format: IfeFormat | undefined = ALL_SYSTEMS.find(
      (s) => s.item_id === delivery.ifeSystem
    )?.formats.find((f) => f.item_id === delivery.ifeFormat);
    return format;
  };

  const onApprove = () => {
    if (orderId) {
      setIsSubmitting(true);
      approveOrder(orderId, () => {
        setIsSubmitting(false);
      });
    }
  };

  const onReject = () => {
    if (orderId) {
      setIsSubmitting(true);
      rejectOrder(orderId, () => {
        setIsSubmitting(false);
      });
    }
  };

  const onRestartOrderDelivery = () => {
    if (orderId) {
      setIsSubmitting(true);
      restartOrderDelivery(orderId, () => {
        setIsSubmitting(false);
      });
    }
    setDotMenuOpen(false);
  };

  const onCancelOrderDelivery = () => {
    if (orderId) {
      setIsSubmitting(true);
      cancelOrderDelivery(orderId, () => {
        dispatch(updateOrderDeliveryStatus({ orderId, status: 'CANCELLED' }));
        setIsSubmitting(false);
      });
    }
    setDotMenuOpen(false);
  };

  const LanguageNames = (languageCodes: string[] | undefined) => {
    return (
      languageCodes
        ?.map((code: string) => languages.find((l) => l.value === code)?.label)
        .join(', ') || ''
    );
  };

  const genericCellStyles = 'px-4 py-3';

  return (
    <>
      <Helmet>
        <title>
          {`Above Media | Order Details${
            !isLoading
              ? ` | ${currentOrder?.orderGroup.title || ''} -
              ${currentOrder?.orderGroup.clientText || ''} (${currentOrder?.externalId})`
              : ''
          }
          `}
        </title>
      </Helmet>
      <Breadcrumbs items={breadcrumbItems} />
      <div className="bg-white container mx-auto border-2 border-gray-200 rounded-xl overflow-hidden mb-8">
        <div className="grid grid-cols-7 p-5 gap-4">
          <div className="col-span-5 flex flex-row justify-between items-center">
            {!isLoading && (
              <>
                <div className="flex flex-row">
                  <div className="font-semibold text-lg text-gray-900">
                    {`${currentOrder?.orderGroup.title || ''} -${' '}
                  ${currentOrder?.orderGroup.clientText || ''} (${currentOrder?.externalId})`}
                  </div>
                </div>
                <div className="flex-grow flex items-start">
                  <Popover open={dotMenuOpen} onOpenChange={(open) => setDotMenuOpen(open)}>
                    <PopoverTrigger asChild>
                      {!showEdit && (
                        <Button variant="default" link rounded>
                          <VDotsIcon className="stroke-gray w-5 h-5" />
                        </Button>
                      )}
                    </PopoverTrigger>
                    <PopoverDropdownContent align="center">
                      <div className="divide-y divide-gray-100 min-w-max flex flex-col justify-start">
                        <Button
                          variant="default"
                          size="sm"
                          block
                          disabled={isSubmitting}
                          onClick={() => {
                            setShowEdit(true);
                            setDotMenuOpen(false);
                          }}
                          className="border-0 bg-white justify-start outline-none">
                          <EditIcon className="w-6 h-6 stroke-gray-500" />
                          Edit
                        </Button>
                        {!showEdit &&
                          (currentUserGroups || []).some((group) =>
                            ['studio_admin', 'above_admin'].includes(group)
                          ) && (
                            <>
                              <Button
                                variant="default"
                                size="sm"
                                block
                                disabled={
                                  currentOrder?.licenseStatus === 'APPROVED' || isSubmitting
                                }
                                onClick={onApprove}
                                className="border-0 bg-white justify-start outline-none">
                                <CheckIcon className="stroke-primary" />
                                Approve Order
                              </Button>
                              <Button
                                variant="default"
                                size="sm"
                                block
                                disabled={
                                  currentOrder?.licenseStatus === 'REJECTED' || isSubmitting
                                }
                                onClick={onReject}
                                className="border-0 bg-white justify-start outline-none">
                                <XCircleIcon className="stroke-danger" />
                                Reject Order
                              </Button>
                            </>
                          )}
                        {['ENCODE_FAILED', 'DELIVERY_FAILED'].includes(
                          currentOrder?.deliveryStatus || ''
                        )}
                        {currentOrder &&
                          currentOrder.licenseStatus === 'APPROVED' &&
                          currentOrder.titles
                            .flatMap((t) => t.deliveries)
                            .filter((d) => !['CANCELLED', 'DELIVERY_SUCCESS'].includes(d.status))
                            .length > 0 && (
                            <Button
                              variant="default"
                              size="sm"
                              block
                              disabled={isSubmitting}
                              onClick={onRestartOrderDelivery}
                              className="border-0 bg-white justify-start outline-none">
                              <RefreshIcon className="stroke-primary" />
                              Restart Order Delivery
                            </Button>
                          )}
                        {!['CANCELLED', 'SUCCESS'].includes(currentOrder?.deliveryStatus || '') && (
                          <Button
                            variant="default"
                            size="sm"
                            block
                            disabled={isSubmitting}
                            onClick={onCancelOrderDelivery}
                            className="border-0 bg-white justify-start outline-none">
                            <XCircleIcon className="stroke-danger" />
                            Update Order Delivery
                          </Button>
                        )}
                        {(currentUserGroups || []).some((group) =>
                          ['above_admin'].includes(group)
                        ) && (
                          <Button
                            variant="default"
                            size="sm"
                            block
                            disabled={
                              isSubmitting ||
                              !['IN_PROGRESS', 'FAILED'].includes(
                                currentOrder?.deliveryStatus || ''
                              )
                            }
                            onClick={() => {
                              window.open(
                                CONTENT_PORTAL_URL +
                                  '/#/o/above_above/workflows/AB/' +
                                  currentOrder?.orderName,
                                '_blank'
                              );
                              setDotMenuOpen(false);
                            }}
                            className="border-0 bg-white justify-start outline-none">
                            <ExternalIcon className="w-5 h-5 stroke-primary" /> Go to cL workflow
                          </Button>
                        )}
                      </div>
                    </PopoverDropdownContent>
                  </Popover>
                </div>
                <div className="flex flex-row justify-end gap-4"></div>
              </>
            )}
          </div>
        </div>
        <div className="relative border-y border-gray-100">
          {isLoading && (
            <>
              <div className="absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-50 z-10"></div>
              <div className="border-t border-gray-100">
                <Skeleton />
              </div>
            </>
          )}
          {!isLoading && (
            <div className="grid grid-cols-7">
              <div className="col-span-5">
                <div className="py-4 px-8">
                  <InvertedListItem
                    title="Organization"
                    description={userOrgsMap[currentOrder?.organization || ''].name}
                  />
                  <InvertedListItem
                    title="Order Group"
                    description={
                      <>
                        <span>{currentOrder?.orderGroup.title || ''} -</span>{' '}
                        <span className="text-gray-400">
                          {currentOrder?.orderGroup.clientText || ''}
                        </span>
                      </>
                    }
                  />
                  <InvertedListItem title="PO Number" description={currentOrder?.externalId} />
                  <InvertedListItem
                    title="Issue Date"
                    description={formatDateTime(currentOrder?.createdAt || '')}
                  />
                  <InvertedListItem title="Brand" description={currentOrder?.brand} />
                  <InvertedListItem
                    title="License Status"
                    description={
                      currentOrder && (
                        <div className="flex flex-row">
                          <Tag
                            size="xs"
                            className="font-normal"
                            variant={
                              ORDER_LICENSE_STATUS_METADATA[currentOrder.licenseStatus]?.variant
                            }>
                            {OrderLicenseStatusLabels[currentOrder.licenseStatus]}
                          </Tag>
                        </div>
                      )
                    }
                  />
                  <InvertedListItem
                    title="Delivery Status"
                    description={
                      currentOrder && (
                        <div className="flex flex-row">
                          <Tag
                            size="xs"
                            className="font-normal"
                            variant={
                              ORDER_DELIVERY_STATUS_METADATA[currentOrder.deliveryStatus]?.variant
                            }>
                            {DerivedOrderDeliveryStatusLabels[currentOrder.deliveryStatus]}
                          </Tag>
                        </div>
                      )
                    }
                    className={'border-none'}
                  />
                </div>
                <div>
                  <table className="min-w-full divide-y divide-gray-200 border-t border-gray-200 text-sm">
                    <tbody className="bg-white divide-y divide-gray-200">
                      {currentOrder?.titles.map((title: Title) => (
                        <>
                          <tr key={title.titleIndex}>
                            <td
                              colSpan={2}
                              className={clsx(genericCellStyles, {
                                'pt-16': true
                              })}>
                              <TitleLabel orderTitle={title} />
                            </td>
                            <td
                              className={clsx(genericCellStyles, {
                                'pt-16': true
                              })}>
                              <h3 className="font-light pt-1 text-right">
                                License &ldquo;{title.dealName || 'all access'}&rdquo; |{' '}
                                {title.licenseStart} - {title.licenseEnd}
                              </h3>
                            </td>
                          </tr>
                          {title?.deliveries &&
                            title.deliveries.map((delivery: OrderTitleDelivery) => (
                              <tr
                                key={delivery?.deliveryId}
                                className="gap-4 border-b border-gray-200 py-1 font-light">
                                <td className={clsx(genericCellStyles)}>
                                  <Button
                                    onClick={() => {
                                      setSelectedDelivery(delivery);
                                      setShowDetails(true);
                                    }}
                                    link
                                    className="underline text-primary justify-start p-0">
                                    {ifeSystemSummaryLabel(delivery)}
                                  </Button>
                                </td>
                                <td className={clsx(genericCellStyles)}>
                                  <DeliveryLanguages delivery={delivery} />
                                </td>
                                <td className={clsx(genericCellStyles)}>
                                  <div className="flex flex-row justify-end item-end">
                                    <div className="flex items-center">
                                      <Tag
                                        size="xs"
                                        variant={
                                          delivery?.status === 'DELIVERY_SUCCESS'
                                            ? 'success'
                                            : delivery?.status === 'CANCELLED'
                                              ? 'error'
                                              : 'warning'
                                        }>
                                        {delivery?.status &&
                                          DeliveryItemStatusLabels[delivery.status]}
                                      </Tag>
                                    </div>
                                    <TitleDeliveryActionSet
                                      order={currentOrder}
                                      delivery={delivery}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <OrderHistory currentOrder={currentOrder} />
            </div>
          )}
          {showEdit && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="">
                <Button
                  onClick={() => {
                    setShowEdit(false);
                    reset();
                  }}
                  className="absolute right-2 top-[-30px] z-50 bg-gray-400 rounded-t px-4 py-1 text-white cursor-pointer text-base hover:bg-gray-500">
                  Close
                </Button>
              </div>
              <div className="bg-gray bg-opacity-70 absolute left-0 right-0 top-0 min-h-full z-50 border-gray-400 border rounded-b">
                <div className="p-0">
                  {/* <div className="bg-gray-200 border-b border-gray-300 text-gray-900 p-4 text-left font-medium text-lg flex flex-row items-center gap-2 w-full">
                  {props.delivery.deliveryId || ''}
                </div> */}
                  <div className="my-0 bg-gray-25 divide-y divide-gray-200">
                    <FormItem label="PO Number">
                      <Input
                        rounded
                        className="w-3/6"
                        placeholder="Enter PO number"
                        error={(formState.errors.externalId as FieldError)?.message}
                        {...register('externalId')}
                      />
                    </FormItem>
                    <FormItem label="Brand">
                      <Controller
                        name="brand"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'Brand is required!'
                          }
                        }}
                        render={({ field }) => (
                          <div className="flex flex-col gap-2 w-full">
                            <div className="flex flex-row gap-4">
                              <Select
                                isInvalid={Object.keys(formState.errors).indexOf('brand') !== -1}
                                className="w-3/6"
                                options={brandOptions}
                                placeholder="Please select brand"
                                onChange={(option: any) => {
                                  field.onChange(option.value);
                                }}
                                value={brandOptions.find((option) => field.value === option.value)}
                              />
                            </div>
                            {formState.errors.brand && (
                              <div className="text-danger-500 mt-1.5 text-sm">
                                {(formState.errors.brand as FieldError).message}
                              </div>
                            )}
                          </div>
                        )}
                      />
                    </FormItem>
                    <div className="flex flex-row-reverse p-4 gap-2">
                      <Button
                        disabled={!formState.dirtyFields || !formState.isValid || isSubmitting}
                        type="submit"
                        rounded
                        variant="primary"
                        className="flex flex-row gap-1">
                        <SaveIcon className="stroke-white" /> Save
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
          <div className="pt-5 pb-8 pr-12 flex flex-row gap-4 items-center justify-center border-t border-gray-100">
            <div className="flex-grow"></div>
            <div>
              <Button block rounded size="sm" href="/orders">
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
      {showDetails && (
        <Modal
          title={selectedDelivery?.deliveryId || ''}
          visible={showDetails}
          setVisible={setShowDetails}
          footer={
            <div className="pb-4 px-4">
              <Button block rounded size="sm" onClick={() => setShowDetails(false)}>
                Close
              </Button>
            </div>
          }>
          <div className="p-4">
            <div className="text-gray-900 font-medium text-sm">
              {getIfeSystem(selectedDelivery)?.item_text || ''}
            </div>
            <div className="my-4 bg-gray-25 divide-y divide-gray-200">
              <SystemRow
                title=""
                isHeader={true}
                configuration="Configuration"
                filename="Filename"
              />
              <SystemRow
                title="Encoding Profile (Template)"
                configuration={selectedDelivery?.vtkTemplate || ''}
                filename={selectedDelivery?.deliveryFileName || ''}
              />
              <SystemRow
                title="Audio Languages"
                configuration={LanguageNames(selectedDelivery?.audioLanguages)}
              />
              <SystemRow
                title="Subtitles"
                configuration={LanguageNames(selectedDelivery?.subtitleLanguages)}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

const InvertedListItem = (props: {
  title?: string;
  description?: string | number | ReactNode;
  help?: string;
  className?: string;
  descriptionClassName?: string;
}) => {
  return (
    <ListItem
      layout="horizontal"
      className={clsx('py-4', props.className)}
      descriptionClassName={clsx('w-7/12', props.descriptionClassName)}
      title={
        <div>
          <div className="font-normal text-gray text-sm">{props.title}</div>
          <p className="font-normal text-gray-400 text-sm">{props.help}</p>
        </div>
      }
      description={<div className="font-semibold text-gray-900 text-sm">{props.description}</div>}
    />
  );
};

const DeliveryLanguages = (props: { delivery: OrderTitleDelivery }) => {
  const missingVideoIssue =
    props.delivery?.contentErrors?.find((a) => a.assetType === 'VIDEO') || null;

  const renderMissingVideoIssue = (assetError: string) => {
    switch (assetError) {
      case ContentErrorType.ASSET_NOT_FOUND:
        return (
          <Tooltip content={<div className="text-gray-500 text-xs">Asset was not found</div>}>
            <NoVideoIcon className="stroke-danger w-6" />
          </Tooltip>
        );
      case ContentErrorType.ASSET_NOT_QCED:
        return (
          <Tooltip content={<div className="text-gray-500 text-xs">Asset was not QCed</div>}>
            <NoVideoIcon className="stroke-gray-500 w-6" />
          </Tooltip>
        );
      case ContentErrorType.NO_HD_QUALITY:
        return (
          <Tooltip content={<div className="text-gray-500 text-xs">Asset is not HD</div>}>
            <NoHDIcon className="stroke-danger w-6" />
          </Tooltip>
        );
      case ContentErrorType.NO_16X9_AR:
        return (
          <Tooltip
            content={<div className="text-gray-500 text-xs">Asset has no 16:9 aspect-ratio</div>}>
            <No16x9Icon className="stroke-danger w-6" />
          </Tooltip>
        );
    }
    return (
      <Tooltip content={<div className="text-gray-500 text-xs">Asset is ready</div>}>
        <VideoIcon className="stroke-success w-6" />
      </Tooltip>
    );
  };

  const missingAudioLanguages =
    props.delivery?.contentErrors
      ?.filter((a) => a.assetType === 'AUDIO' && a.language)
      .map((e) => e.language) || [];
  const existingAudioLanguages = props.delivery?.audioLanguages?.filter(
    (lang) => !missingAudioLanguages.includes(lang)
  );

  const missingSubtitleLanguages =
    props.delivery?.contentErrors
      ?.filter((a) => ['SUBTITLE', 'CLOSED_CAPTION'].includes(a.assetType) && a.language)
      .map((a) => (a.language === 'en' && a.assetType === 'CLOSED_CAPTION' ? 'ecc' : a.language)) ||
    [];
  const existingSubtitleLanguages = props.delivery?.subtitleLanguages?.filter(
    (lang) => !missingSubtitleLanguages.includes(lang)
  );

  return (
    <div className={`flex flex-row gap-2 items-center`}>
      <span className="flex flex-row items-center gap-2">
        <span className="font-bold">{props.delivery.editVersion}</span> |{' '}
        {renderMissingVideoIssue(missingVideoIssue?.error || '')}
        {(existingAudioLanguages.length > 0 || missingAudioLanguages.length > 0) && (
          <span className="flex flex-row gap-1 items-center">
            {' '}
            | Dubs:
            {existingAudioLanguages.length > 0 && (
              <Tag variant="success" size="xs" className={`rounded-none uppercase p-1`}>
                {existingAudioLanguages.join(', ')}
              </Tag>
            )}
            {missingAudioLanguages.length > 0 && (
              <Tag variant="default" size="xs" className={`rounded-none uppercase p-1`}>
                {missingAudioLanguages.join(', ')}
              </Tag>
            )}
          </span>
        )}
        {(existingSubtitleLanguages.length > 0 || missingSubtitleLanguages.length > 0) && (
          <span className="flex flex-row gap-1 items-center">
            {' '}
            | Subs:
            {existingSubtitleLanguages.length > 0 && (
              <Tag variant="success" size="xs" className={`rounded-none uppercase p-1`}>
                {existingSubtitleLanguages.join(', ')}
              </Tag>
            )}
            {missingSubtitleLanguages.length > 0 && (
              <Tag variant="default" size="xs" className={`rounded-none uppercase p-1`}>
                {missingSubtitleLanguages.join(', ')}
              </Tag>
            )}
          </span>
        )}
      </span>
    </div>
  );
};

const SystemRow = ({
  title,
  configuration,
  filename,
  isHeader = false
}: {
  title?: string;
  configuration?: string;
  filename?: string;
  isHeader?: boolean;
}) => {
  return (
    <div
      className={clsx('grid grid-cols-3 gap-4 ', {
        'text-xs text-gray font-medium p-2 bg-gray-50': isHeader,
        'text-sm text-gray-900 p-3': !isHeader
      })}>
      <div className="text-gray-700 text-right font-light">{title}</div>
      <div>{configuration}</div>
      <div>{filename}</div>
    </div>
  );
};
