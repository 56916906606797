import { ReactNode, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import MovieIcon from '../../assets/icons/movie.svg?react';
import SeriesIcon from '../../assets/icons/series.svg?react';
import YoutubeIcon from '../../assets/icons/youtube.svg?react';
import EpisodeIcon from '../../assets/icons/episode.svg?react';
import ListIcon from '../../assets/icons/list.svg?react';
import {
  initialSelectedCandidate,
  selectIngest,
  setCandidates,
  setSelectedCandidate,
  setStudio,
  setTitleCandidates
} from '../../features/ingestion/ingestSlice';
import HomeIcon from '../../assets/icons/home.svg?react';
import FileLocationsIcon from '../../assets/icons/file-locations.svg?react';
import FindTitleIcon from '../../assets/icons/find-title.svg?react';
import PlusIcon from '../../assets/icons/plus.svg?react';
import ConfigureAssetsIcon from '../../assets/icons/configure-assets.svg?react';
import Breadcrumbs, { BreadcrumbsItem } from '../../components/ui/Breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { Collapse, CollapseSection } from '../../components/ui/Collapse';
import { Controller, FieldError, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import Input from '../../components/ui/Input';
import Button from '../../components/ui/Button';
import Select from '../../components/ui/Select';
import {
  useLazyFetchEidrChildrenQuery,
  useLazyFetchEidrSearchQuery,
  useLazyFetchTitleMetadataQuery
} from '../../features/ingestion/ingestionApi';
import List, { ListItem } from '../../components/ui/List';
import { EidrRecord, EidrReferentType } from '../../features/eidr/types';
import { SourceCatalog, TitleCandidate } from '../../features/ingestion/types';
import { isEidrId, isMpmNumber } from '../../common/validation';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Pagination from '../../components/ui/Pagination';
import Loader from '../../components/ui/Loader';
import { ListEidr } from '../../components/ingestion/ListEidr';
import AddMissingSeasonEpisode from '../../components/ingestion/AddMissingSeasonEpisode';
import { OrderItemTitleTypeLabels, ReferentType } from '../../common/types';
import { eidrRecordMpmNumber, getOptions } from '../../common/utils';
import Alert from '../../components/ui/Alert';
import clsx from 'clsx';

export interface IngestionSearchProps {
  query: string;
  referentType?: string;
}

export default function IngestContentMetadataPage() {
  const ingest = useAppSelector(selectIngest);
  const [searchType, setSearchType] = useState('title');
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [eidrRecordsTrigger, { data, isUninitialized: isUninitializedTerm, isFetching }] =
    useLazyFetchEidrSearchQuery();
  const dispatch = useAppDispatch();
  const [eidrSeasonTrigger, { data: seasons, isFetching: isFetchingSeasons }] =
    useLazyFetchEidrChildrenQuery();
  const [eidrEpisodeTrigger, { data: episodes, isFetching: isFetchingEpisodes }] =
    useLazyFetchEidrChildrenQuery();
  const [titleMetadataTrigger, { isFetching: isFetchingTitlesMeta, isUninitialized }] =
    useLazyFetchTitleMetadataQuery();
  const [showRoots, setShowRoots] = useState(false);
  const [showSeasons, setShowSeasons] = useState(false);
  const [showEpisodes, setShowEpisodes] = useState(false);
  const [addMissingSeason, setAddMissingSeason] = useState(false);
  const [addMissingEpisode, setAddMissingEpisode] = useState(false);
  const [selectedRootEidr, setSelectedRootEidr] = useState<EidrRecord>();
  const [selectedSeasonEidr, setSelectedSeasonEidr] = useState<EidrRecord>();
  const [selectedEpisodeEidr, setSelectedEpisodeEidr] = useState<EidrRecord>();
  const isIngestCopyCandidate = Object.keys(ingest.selectedCandidate).includes('processStatus');
  const navigate = useNavigate();
  const ROW_PER_PAGE = 25;

  const { register, handleSubmit, formState, control } = useForm({
    defaultValues: {
      query: ingest.preselectedMpmNumber || '',
      reftype: 'SERIES'
    }
  });

  useEffect(() => {
    if (ingest.preselectedMpmNumber) {
      setSearchType('mpm');
    }
  }, [ingest.preselectedMpmNumber]);

  const search = useWatch({ name: 'query', control: control });
  const reftype = useWatch({ name: 'reftype', control: control });

  const getPage = () => {
    const pageNumber = Number(searchParams.get('pageNumber')) || 0;
    if (!search) return;
    eidrRecordsTrigger({
      search: search,
      pageSize: ROW_PER_PAGE,
      pageNumber: pageNumber + 1,
      reftype: reftype
    }).then(() => {
      setShowRoots(true);
      setCurrentPageNumber(pageNumber);
    });
  };

  const onSubmit: SubmitHandler<IngestionSearchProps> = (values) => {
    dispatch(setCandidates([]));
    setSelectedEpisodeEidr(undefined);
    if (searchType === 'mpm') {
      titleMetadataTrigger({ mpm: values.query }).then((response) => {
        if (!response.data) {
          alert(`Could not find any metadata for MPM: ${values.query}`);
          return;
        }
        dispatch(setCandidates(response.data));
      });
    } else if (searchType === 'eidr') {
      titleMetadataTrigger({ eidr: values.query }).then((response) => {
        if (!response.data) {
          alert(`Could not find any metadata for EIDR: ${values.query}`);
          return;
        }
        dispatch(setCandidates(response.data));
      });
    } else {
      setCurrentPageNumber(0);
      setSearchParams(values.query ? { search: values.query, pageNumber: '0' } : {});
      getPage();
    }
  };

  const breadcrumbItems: BreadcrumbsItem[] = [
    {
      link: '/',
      icon: <HomeIcon />
    },
    {
      title: 'Ingestion',
      link: '/ingestion'
    },
    {
      title: 'New'
    }
  ];

  const getEidrIcon = (referentType: EidrReferentType): ReactNode => {
    switch (referentType) {
      case EidrReferentType.SERIES:
        return <SeriesIcon className="w-6 h-6 stroke-gray-300" />;
      case EidrReferentType.SEASON:
        return <ListIcon className="w-6 h-6 stroke-gray-300" />;
      case EidrReferentType.TV:
        return <EpisodeIcon className="w-6 h-6 stroke-gray-300" />;
      case EidrReferentType.MOVIE:
        return <MovieIcon className="w-6 h-6 stroke-gray-300" />;
      case EidrReferentType.SHORT:
        return <EpisodeIcon className="w-6 h-6 stroke-gray-300" />;
      case EidrReferentType.WEB:
        return <YoutubeIcon className="w-6 h-6 stroke-gray-300" />;
    }
  };

  const getSecondary = (candidate: TitleCandidate) => {
    let result = ``;
    if (candidate.referentType !== '') {
      result = `${candidate.referentType}, `;
    }
    if (candidate.seriesReleaseDate !== '') {
      result = `Release date: ${candidate.seriesReleaseDate}, `;
    }
    const mpm = candidate.mpmNumber;
    if (mpm) {
      result += `MPM: ${mpm}, `;
    }
    const seasonNumber = candidate.seasonNumber;
    if (seasonNumber) {
      result += `Season: ${seasonNumber}, `;
    }
    const episodeNumber = candidate.episodeNumber;
    if (episodeNumber) {
      result += `Episode: ${episodeNumber}`;
    }
    return result;
  };

  const getTitle = (candidate: TitleCandidate) => {
    let title = `${candidate.titleName} (${candidate.mpmNumber})`;
    if (candidate.seriesName) {
      title = `${candidate.seriesName} -> ${title}`;
    }
    if (
      (candidate.isEpisode && candidate.seriesEidrId === '') ||
      (!candidate.isEpisode && candidate.eidrId === '')
    ) {
      return (
        <div className="flex flex-row gap-4">
          {title}
          <Alert className="px-1 py-0" variant={'warning'}>
            {candidate.isEpisode ? 'Series' : ''} EIDR record is missing
          </Alert>
        </div>
      );
    }
    return title;
  };

  const onQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    dispatch(setCandidates([]));
    setSelectedEpisodeEidr(undefined);
    dispatch(setSelectedCandidate({ mpmNumber: '' } as TitleCandidate));
    if (isMpmNumber(value)) {
      setSearchType('mpm');
    } else if (isEidrId(value)) {
      setSearchType('eidr');
    } else {
      setSearchType('title');
    }
  };

  const reftypeOptions = getOptions(OrderItemTitleTypeLabels);

  const handleRootEidrClick = (eidr: EidrRecord) => {
    setSelectedRootEidr(eidr);
    setSelectedSeasonEidr(undefined);

    if (eidr.ReferentType !== 'Series') {
      const candidate: TitleCandidate = {
        sourceCatalog: SourceCatalog.EIDR,
        mpmNumber: '',
        eidrId: eidr.ID,
        titleName: eidr.ResourceName.value,
        referentType: eidr.ReferentType.toUpperCase() as ReferentType,
        studio: '',
        editVersion: '',
        isEpisode: false
      };

      dispatch(setTitleCandidates([candidate]));
      dispatch(setSelectedCandidate(candidate));
      navigate('/ingestion/new/confirmation');
      return;
    }
    setShowSeasons(true);
    eidrSeasonTrigger({ eidrId: eidr.ID });
  };

  const handleSeasonEidrClick = (eidr: EidrRecord) => {
    setAddMissingSeason(false);
    setSelectedSeasonEidr(eidr);
    setShowEpisodes(true);
    setSelectedEpisodeEidr(undefined);
    eidrEpisodeTrigger({ eidrId: eidr.ID });
  };

  const handleChangePage = (pageNumber: number) => {
    searchParams.set('pageNumber', pageNumber.toString());
    setSearchParams(searchParams);
    setCurrentPageNumber(pageNumber);
    getPage();
  };

  const handleEpisodeEidrClick = (eidr: EidrRecord) => {
    if (!selectedRootEidr) return;
    if (!selectedSeasonEidr) return;
    setAddMissingEpisode(false);
    setSelectedEpisodeEidr(eidr);

    const candidate: TitleCandidate = {
      sourceCatalog: SourceCatalog.EIDR,
      mpmNumber:
        selectedEpisodeEidr && selectedEpisodeEidr.AlternateID
          ? eidrRecordMpmNumber(selectedEpisodeEidr)
          : '',
      eidrId: eidr.ID,
      titleName: eidr.ResourceName.value,
      referentType: eidr.ReferentType.toUpperCase() as ReferentType,
      studio: selectedRootEidr.AboveRecord?.studio || '',
      editVersion: '',
      isEpisode: true,
      seriesMpmNumber:
        selectedRootEidr && selectedRootEidr.AlternateID
          ? eidrRecordMpmNumber(selectedRootEidr)
          : '',
      seriesAboveId: selectedRootEidr.AboveRecord?.id || '',
      seriesFolderLocation: selectedRootEidr.AboveRecord?.folder_location || '',
      seriesEidrId: selectedRootEidr.ID,
      seriesName: selectedRootEidr.ResourceName.value,
      seriesReleaseDate: selectedRootEidr.ReleaseDate,
      seasonNumber: parseInt(selectedSeasonEidr.ExtraObjectMetadata.SeasonInfo.SequenceNumber),
      episodeNumber: parseInt(
        eidr.ExtraObjectMetadata.EpisodeInfo.SequenceInfo.DistributionNumber.value
      )
    };

    dispatch(setTitleCandidates([candidate]));
    dispatch(setSelectedCandidate(candidate));
    if (candidate.aboveTopParentData?.studio) {
      dispatch(setStudio(candidate.aboveTopParentData.studio));
    }
  };

  const handleAddMissingSeason = () => {
    setShowEpisodes(false);
    setAddMissingSeason(true);
    setAddMissingEpisode(false);
  };

  return (
    <>
      <Helmet>
        <title>Above Media | Ingestion | Content Metadata</title>
      </Helmet>
      <Breadcrumbs items={breadcrumbItems} />

      <div className="bg-white container mx-auto border-2 border-gray-200 rounded-xl mb-8">
        <div className="px-5 py-5 flex flex-row gap-4 items-center justify-center border-b border-gray-200">
          <div className="grow gap-1 flex flex-col font-semibold text-lg text-gray-900">
            New Ingestion Process
            <p className="text-gray font-normal text-sm">Fill out your ingestion details here.</p>
          </div>
        </div>
        <div className="relative flex flex-col divide-y divide-gray-100">
          <div>
            <Collapse activeSection="content-metadata" urlMode={true}>
              <CollapseSection
                icon={<FileLocationsIcon className="w-10 h-10" />}
                id="file-locations"
                title="Validate File Locations"
                active={isIngestCopyCandidate}>
                <span />
              </CollapseSection>
              <CollapseSection
                icon={<FindTitleIcon className="w-10 h-10" />}
                id="content-metadata"
                title="Find Title"
                active={isIngestCopyCandidate}>
                <div className="p-4 bg-primary-200 border border-primary-300 flex flex-col">
                  <form onSubmit={handleSubmit(onSubmit)} className="flex flex-row gap-2">
                    <Input
                      id="query"
                      placeholder="Search by Title, MPM number or  EIDR"
                      className="grow"
                      {...register('query', { required: true, onChange: onQueryChange })}
                      rounded
                      error={Boolean(formState.errors.query)}
                    />
                    <Controller
                      name="reftype"
                      control={control}
                      render={({ field }) => (
                        <div className="flex flex-col gap-2 w-2/12">
                          <div>
                            <Select
                              isInvalid={Object.keys(formState.errors).indexOf('reftype') !== -1}
                              isDisabled={searchType !== 'title'}
                              options={reftypeOptions}
                              placeholder="Please referent type..."
                              onChange={(option: any) =>
                                option && option.value
                                  ? field.onChange(option.value)
                                  : field.onChange('')
                              }
                              value={reftypeOptions.filter(
                                (option) => field.value === option.value
                              )}
                            />
                          </div>
                          {formState.errors.reftype && (
                            <div className="text-danger-500 mt-1.5 text-sm">
                              {(formState.errors.reftype as FieldError).message}
                            </div>
                          )}
                        </div>
                      )}
                    />
                    <Button rounded variant="primary" type="submit">
                      Search
                    </Button>
                  </form>
                </div>
                <div
                  className={clsx('flex flex-col gap-4', {
                    'py-4': data || ingest.candidates.length > 0
                  })}>
                  {searchType !== 'mpm' &&
                    data &&
                    !isFetching &&
                    data?.totalMatches > ROW_PER_PAGE && (
                      <div className="mx-4">
                        <Pagination
                          totalItems={data?.totalMatches || 0}
                          currentPage={currentPageNumber || 0}
                          onPageChange={handleChangePage}
                          itemsPerPage={ROW_PER_PAGE}
                        />
                      </div>
                    )}
                  {(isFetching || isFetchingTitlesMeta) && (
                    <div className="absolute z-50 bg-white bg-opacity-60 top-0 bottom-0 left-0 right-0 flex items-center justify-center">
                      <Loader />
                    </div>
                  )}
                  {searchType === 'title' &&
                    !isFetching &&
                    !isUninitializedTerm &&
                    data &&
                    data.results.length === 0 && (
                      <Alert variant="warning" className="mx-4" rounded>
                        No results were found, refine your search and try again!
                      </Alert>
                    )}
                  {searchType !== 'mpm' &&
                    !isFetching &&
                    showRoots &&
                    data &&
                    data.results.length > 0 && (
                      <ListEidr
                        header="Titles"
                        data={data}
                        selectedEidr={selectedRootEidr}
                        handleEidrClick={handleRootEidrClick}
                      />
                    )}

                  {isFetchingSeasons && (
                    <div className="absolute z-50 bg-white bg-opacity-60 top-0 bottom-0 left-0 right-0 flex items-center justify-center">
                      <Loader />
                    </div>
                  )}
                  {searchType !== 'mpm' && !isFetchingSeasons && showSeasons && seasons && (
                    <>
                      <ListEidr
                        header={'Season Selection'}
                        data={seasons}
                        selectedEidr={selectedSeasonEidr}
                        handleEidrClick={handleSeasonEidrClick}
                        footer={
                          !selectedSeasonEidr && (
                            <Button onClick={handleAddMissingSeason} rounded variant={'primary'}>
                              <PlusIcon className="stroke-white" /> Add missing season & episode
                            </Button>
                          )
                        }
                      />
                    </>
                  )}
                  {isFetchingEpisodes && (
                    <div className="absolute z-50 bg-white bg-opacity-60 top-0 bottom-0 left-0 right-0 flex items-center justify-center">
                      <Loader />
                    </div>
                  )}
                  {searchType !== 'mpm' && !isFetchingEpisodes && showEpisodes && episodes && (
                    <>
                      <ListEidr
                        header="Episode selection"
                        data={episodes}
                        selectedEidr={selectedEpisodeEidr}
                        handleEidrClick={handleEpisodeEidrClick}
                        footer={
                          !selectedEpisodeEidr && (
                            <Button
                              onClick={() => setAddMissingEpisode(true)}
                              rounded
                              variant="primary">
                              <PlusIcon className="stroke-white" /> Add missing episode
                            </Button>
                          )
                        }
                      />
                    </>
                  )}

                  {['mpm', 'eidr'].indexOf(searchType) !== -1 &&
                    !isUninitialized &&
                    !isFetchingTitlesMeta &&
                    ingest.candidates.length === 0 && (
                      <Alert variant="warning" className="mx-4" rounded>
                        No results were found, refine your search and try again!
                      </Alert>
                    )}

                  {['mpm', 'eidr'].indexOf(searchType) !== -1 && ingest.candidates.length > 0 && (
                    <div className="p-4">
                      <List header="Title">
                        {ingest.candidates.map((candidate, index) => (
                          <ListItem
                            key={index}
                            onClick={() => {
                              if (
                                (candidate.isEpisode && candidate.seriesEidrId === '') ||
                                (!candidate.isEpisode && candidate.eidrId === '')
                              ) {
                                alert(
                                  `You cannot select this candidate because we were not able to find the ${candidate.isEpisode ? 'series' : ''} EIDR record for this candidate!`
                                );
                                return;
                              }
                              if (ingest.selectedCandidate.mpmNumber === candidate.mpmNumber) {
                                dispatch(setSelectedCandidate(initialSelectedCandidate));
                              } else {
                                dispatch(setSelectedCandidate(candidate));
                              }
                            }}
                            title={getTitle(candidate)}
                            description={getSecondary(candidate)}
                            icon={getEidrIcon(candidate.referentType as EidrReferentType)}
                            selected={ingest.selectedCandidate.mpmNumber === candidate.mpmNumber}
                          />
                        ))}
                      </List>
                    </div>
                  )}
                </div>
                {(ingest.selectedCandidate.mpmNumber !== '' || selectedEpisodeEidr) &&
                  !isIngestCopyCandidate && (
                    <div className="flex flex-row-reverse px-5 pb-5 gap-4">
                      <Button
                        variant="primary"
                        isRouterLink={true}
                        href="/ingestion/new/confirmation"
                        rounded
                        type="button"
                        size="lg">
                        Confirm Assets
                      </Button>
                    </div>
                  )}
              </CollapseSection>
              <CollapseSection
                icon={<ConfigureAssetsIcon className="w-10 h-10" />}
                id="confirmation"
                title="Configure Assets"
                active={isIngestCopyCandidate}>
                <span />
              </CollapseSection>
            </Collapse>
            {selectedRootEidr && addMissingEpisode && (
              <AddMissingSeasonEpisode
                open={addMissingEpisode}
                setOpen={setAddMissingEpisode}
                onClose={() => {
                  setAddMissingEpisode(false);
                  navigate('/ingestion/new/confirmation');
                }}
                selectedRootEidr={selectedRootEidr}
                selectedSeasonEidr={selectedSeasonEidr}
                selectedEpisodeEidr={selectedEpisodeEidr}
              />
            )}
            {selectedRootEidr && addMissingSeason && (
              <AddMissingSeasonEpisode
                open={addMissingSeason}
                setOpen={setAddMissingSeason}
                onClose={() => {
                  setAddMissingSeason(false);
                  navigate('/ingestion/new/confirmation');
                }}
                selectedRootEidr={selectedRootEidr}
                selectedSeasonEidr={selectedSeasonEidr}
                selectedEpisodeEidr={selectedEpisodeEidr}
                mixMode={true}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
