import React, { useState, ClipboardEvent, useEffect } from 'react';
import Button from '../ui/Button';
import MovieIcon from '../../assets/icons/movie.svg?react';
import SeriesIcon from '../../assets/icons/series.svg?react';
import EpisodeIcon from '../../assets/icons/episode.svg?react';
import HelpIcon from '../../assets/icons/help.svg?react';
import SearchIcon from '../../assets/icons/search.svg?react';
import ShortIcon from '../../assets/icons/short.svg?react';
import DocumentaryIcon from '../../assets/icons/documentary.svg?react';
import SpecialIcon from '../../assets/icons/special.svg?react';
import FormItem from '../ui/FormItem';
import Select from '../ui/Select';
import { Collapse, CollapseSection } from '../ui/Collapse';
import {
  setCurrentSection,
  appendAssets,
  setOrderAssetMode,
  setSelectedTitles,
  setSystems,
  setSelectedRows
} from '../../features/orders/orderSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import Table from '../ui/Table';
import { formatStudio } from '../shared/Formatting';
import { Tooltip } from '../ui/Tooltip';
import LanguagePicker, { LanguageOption } from './LanguagePicker';
import { Controller, FieldError, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { getOptions } from '../../common/utils';
import { EditVersionLabels } from '../../common/types';
import { audioLanguages, subtitleLanguages } from '../../common/languages';
import Switch from '../ui/Switch';
import { TitleCard } from '../../features/titleCard/types';
import SystemPicker from './SystemPicker';
import {
  EncodeSystem,
  Organization,
  OrganizationAirline
} from '../../features/organizations/types';
import Input from '../ui/Input';
import { transform } from 'lodash';
import { renderEdits } from '../../pages/catalog/CatalogPage';
import { Title } from '../../features/catalog/types';
import { ALL_SYSTEMS } from '../../common/target_systems';
import { TitleLabel } from '../../components/shared/TitleLabel';

export interface AssetsViewProps {
  orderGroupId: string;
  organization: string;
  userOrgs: Organization[];
}

export interface AssetsFormProps {
  editVersion: string;
  systems: string[];
  audioLanguages: string[];
  subtitleLanguages: string[];
  includeVideo?: boolean;
  titleCard?: string;
  destination?: string;
  requireHd?: boolean;
  require16x9?: boolean;
}

export default function AssetsView(props: AssetsViewProps) {
  const {
    currentSection,
    titles,
    titleCards,
    orderGroups,
    systems,
    selectedTitles,
    selectedRows,
    selectedAsset,
    selectedSystem,
    orderAssetMode
  } = useSelector((state: RootState) => state.order);
  const dispatch = useDispatch();
  const [assetConfig, setAssetConfig] = useState<AssetsFormProps>();
  const [assetsType, setAssetsType] = useState<string>('encoding');
  const selectedOrg = props.userOrgs.find((org) => org.orgId === props.organization);
  const selectedAirline = orderGroups.find((group) => group._id === props.orderGroupId);

  const [airlineSystems, setAirlineSystems] = useState<EncodeSystem[]>(
    selectedOrg && selectedAirline
      ? (selectedOrg?.airlines.find(
          (item: OrganizationAirline) => item.iataCode === selectedAirline?.clientId
        )?.encodeSystems as EncodeSystem[])
      : []
  );

  const aboveMezzanine = ALL_SYSTEMS.find((s) => s.item_id === 'above_mezzanine');
  const mezzaninePoDestinationsOptions = (
    aboveMezzanine?.formats[0].po_destinations.map((poDestination) => ({
      label: poDestination.display_name,
      value: poDestination.destination_id
    })) || []
  ).filter((item) => selectedOrg?.mezzaninePoDestinations.includes(item.value));

  const systemsOptions = airlineSystems?.map((item, index) => ({
    value: `${item.vtkTemplate}-${item.poDestination}`,
    label: item.ifeSystem,
    ifeSystem: item.ifeSystem,
    ifeFormat: item.ifeFormat,
    poDestination: item.poDestination,
    vtkTemplate: item.vtkTemplate,
    id: index,
    filename: ''
  }));

  const { handleSubmit, control, formState, setValue } = useForm<AssetsFormProps>();
  const {
    handleSubmit: handleSubmitFiles,
    control: controlFiles,
    formState: formStateFiles,
    setValue: setFilesValue
  } = useForm<any>();
  const filesFieldValue = useWatch({ name: 'files', control: controlFiles });

  const titleCardOptions = titleCards.map((item: TitleCard) => ({
    value: item.s3Url,
    label: item.title
  }));

  const editVersionOptions = getOptions(EditVersionLabels);

  const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
    const pastedData = event.clipboardData.getData('Text');
    const splitFilenames = pastedData.split('\n').map((f) => f.trim());
    if (splitFilenames && splitFilenames.length > 1) {
      const allInputs: HTMLInputElement[] = Array.from(
        document.querySelectorAll('#define-filenames input')
      );
      const selectedIndex = allInputs.indexOf(event.target as HTMLInputElement);
      event.preventDefault();
      const files: any = {};
      allInputs.forEach((input, index) => {
        if (index >= selectedIndex) {
          files[input.id] = null;
        }
      });
      Object.keys(files).forEach((key, index) => {
        files[key] = splitFilenames[index] || null;
      });
      setFilesValue('files', { ...filesFieldValue, ...files });
    }
  };

  const iconsMap = {
    SERIES: <SeriesIcon className="stroke-gray-300" />,
    MOVIE: <MovieIcon className="stroke-gray-300" />,
    EPISODE: <EpisodeIcon className="stroke-gray-300" />,
    SHORT: <ShortIcon className="stroke-gray-300" />,
    DOCUMENTARY: <DocumentaryIcon className="fill-gray-300" />,
    SPECIAL: <SpecialIcon className="fill-gray-300" />
  };

  const columns = [
    {
      key: 'name',
      title: 'Title',
      allowSorting: true,
      render: (item: Title) => (
        <div className="text-gray text-sm flex flex-row gap-4 items-center">
          {iconsMap[item.type]}
          <TitleLabel catalogTitle={item} />
        </div>
      )
    },
    {
      key: 'studio',
      title: 'Studio',
      allowSorting: false,
      render: (item: Title) => (
        <span className="text-gray text-sm">{formatStudio(item.studio || '')}</span>
      )
    },
    {
      key: 'edits',
      title: 'Edits',
      className: 'w-2/6',
      renderTitle: () => (
        <span className="flex flex-row gap-2">
          Edits{' '}
          <Tooltip
            content={
              <div className="text-gray-500 text-xs flex flex-col gap-2">
                <div className="text-gray-700 font-semibold text-sm">EDITS LEGEND</div>
                <div className="flex flex-col gap-1">
                  <div>
                    <span className="font-semibold">TH</span> (Theatrical)
                  </div>
                  <div>
                    <span className="font-semibold">ED</span> (AirlineEdit)
                  </div>
                  <div>
                    <span className="font-semibold">ME</span> (General Middle East Edit)
                  </div>
                  <div>
                    <span className="font-semibold">SAU</span> (Saudi Edit)
                  </div>
                </div>
              </div>
            }>
            <HelpIcon />
          </Tooltip>
        </span>
      ),
      render: (item: Title) => renderEdits(item)
    }
  ];

  const onSubmit: SubmitHandler<AssetsFormProps> = (values) => {
    setAssetConfig(values);
    dispatch(setCurrentSection('define-filenames'));
  };

  const onFilesSubmit: SubmitHandler<any> = (values) => {
    const assetConfigObj = transform(
      values.files,
      (result: any, value, key: string) => {
        const match = key.match(/(\d+)-(.*)/);
        if (match === null || match.length < 3) return false;
        const id = match[1];
        const subKey = match[2];
        const payloadSystems = assetConfig?.systems || systems;
        const isSystemExists = payloadSystems.find((system: any) => system.value === subKey);
        if (assetConfig) {
          assetConfig.subtitleLanguages = assetConfig.subtitleLanguages || [];
          assetConfig.audioLanguages = assetConfig.audioLanguages || [];
        }
        if (!result[id]) {
          result[id] = {};
          result[id].filenames = {};
        }
        if (isSystemExists) {
          result[id].filenames[subKey] = value;
        }
        if (!result[id].assetSystems) {
          result[id].assetSystems = {};
        }
        const system = systemsOptions.find((system) => system.value === subKey);
        let systemLabel = '';
        if (system) {
          const destination =
            system.poDestination.charAt(0).toUpperCase() + system.poDestination.slice(1);
          systemLabel = `${destination} - ${system.ifeSystem} (${system.ifeFormat})`;
        }
        if (isSystemExists) {
          result[id].assetSystems[subKey] = { ...assetConfig, assetsType, systemLabel };
        }
      },
      {}
    );
    dispatch(appendAssets(assetConfigObj));
    dispatch(setOrderAssetMode(undefined));
    dispatch(setCurrentSection('select-titles'));
    dispatch(setSelectedRows(new Set()));
    dispatch(setSystems([]));
  };

  const onSectionChange = (value?: string) => {
    if (currentSection === 'configure-order-assets' && value === 'define-filenames') {
      handleSubmit(onSubmit)();
    } else {
      dispatch(setCurrentSection(value));
    }
  };
  const onAddMezzanine = () => {
    dispatch(
      setSystems([
        {
          value: 'mezzanine',
          label: 'Mezzanine',
          ifeSystem: 'Mezzanine',
          ifeFormat: 'above_mezzanine_default',
          poDestination: '',
          vtkTemplate: '',
          id: 1,
          filename: ''
        }
      ])
    );
    setAssetsType('mezzanine');
    dispatch(setCurrentSection('configure-order-assets'));
  };

  useEffect(() => {
    if (assetsType === 'mezzanine' && !selectedAsset && !selectedSystem) {
      setValue('includeVideo', true);
    }
  }, [assetsType]);

  useEffect(() => {
    if (selectedAsset && selectedSystem) {
      setAssetsType(selectedAsset.assetSystems[selectedSystem].assetsType);
      setValue('editVersion', selectedAsset.assetSystems[selectedSystem].editVersion);
      setValue('audioLanguages', selectedAsset.assetSystems[selectedSystem].audioLanguages);
      setValue('subtitleLanguages', selectedAsset.assetSystems[selectedSystem].subtitleLanguages);
      setValue('requireHd', selectedAsset.assetSystems[selectedSystem].requireHd);
      setValue('require16x9', selectedAsset.assetSystems[selectedSystem].require16x9);
      setValue('includeVideo', selectedAsset.assetSystems[selectedSystem].includeVideo);
      setValue('titleCard', selectedAsset.assetSystems[selectedSystem].titleCard);
      setValue('destination', selectedAsset.assetSystems[selectedSystem].destination);
      const key = Array.from(selectedRows)[0];
      const filesnames = Object.keys(selectedAsset.filenames);
      filesnames.forEach((filename) => {
        setFilesValue(`files.${key}-${filename}`, selectedAsset.filenames[filename]);
      });
    }
  }, [selectedAsset, selectedSystem]);

  return (
    <>
      <div
        onClick={() => dispatch(setOrderAssetMode(undefined))}
        className="absolute right-2 top-[-30px] z-50 bg-gray-400 rounded-t px-4 py-1 text-white cursor-pointer">
        Close
      </div>
      <div className="bg-gray bg-opacity-70 absolute left-0 right-0 top-0 min-h-full z-50 border-gray-400 border rounded-b">
        <Collapse
          onSectionChange={(value?: string) => onSectionChange(value)}
          activeSection={currentSection || 'select-titles'}
          reduxMode={true}>
          <CollapseSection id="select-titles" title="Select Titles">
            <div className="p-4">
              <div className="border-gray-100 border-l border-r">
                <Table
                  data={titles || []}
                  columns={columns}
                  multipleSelection={true}
                  rowId="_id"
                  selectedRows={selectedRows}
                  disabledRows={selectedTitles ? [selectedTitles[0]?._id] : []}
                  onSelectChange={(keys) => {
                    dispatch(setSelectedRows(keys));
                    dispatch(
                      setSelectedTitles(
                        titles.filter(
                          (item) =>
                            Array.from(keys)
                              .map((key) => key.toString())
                              .indexOf(item._id) !== -1
                        )
                      )
                    );
                  }}
                  pagination={{
                    totalItems: titles.length || 0,
                    itemsPerPage: 30,
                    currentPage: 1,
                    onPageChange: (page) => console.log(page)
                  }}
                  noDataIcon={<SearchIcon className="w-8 h-8 stroke-gray-400" />}
                  noDataLabel="No titles were found!"
                  noDataDescription="Your search did not match any title. Please try again later."
                />
              </div>
            </div>
            <div className="flex flex-row-reverse p-4 gap-2 pt-0">
              <Button
                variant="primary"
                rounded
                disabled={selectedRows.size === 0}
                type="button"
                onClick={onAddMezzanine}>
                Add Mezzanine
              </Button>
              <Button
                variant="primary"
                rounded
                disabled={selectedRows.size === 0}
                type="button"
                onClick={() => {
                  setAssetsType('encoding');
                  dispatch(setCurrentSection('configure-order-assets'));
                }}>
                Add Encoding
              </Button>
            </div>
          </CollapseSection>
          <CollapseSection
            id="configure-order-assets"
            title="Configure Order Assets"
            active={selectedRows.size > 0}>
            <form className="flex flex-col divide-y divide-gray-100">
              <FormItem label="Edit Version">
                <Controller
                  name="editVersion"
                  rules={{
                    required: {
                      value: true,
                      message: 'Edit Version is required!'
                    }
                  }}
                  control={control}
                  render={({ field }) => (
                    <div className="flex flex-col gap-2 w-full">
                      <div className="flex flex-row gap-4">
                        <Select
                          isInvalid={Object.keys(formState.errors).indexOf('editVersion') !== -1}
                          className="w-3/6"
                          options={editVersionOptions}
                          placeholder="Please select edit version"
                          onChange={(option: any) => field.onChange(option.value)}
                          value={editVersionOptions.find((option) => field.value === option.value)}
                          readOnly={false}
                        />
                      </div>
                      {formState.errors.editVersion && (
                        <div className="text-danger-500 mt-1.5 text-sm">
                          {(formState.errors.editVersion as FieldError).message}
                        </div>
                      )}
                    </div>
                  )}
                />
              </FormItem>
              {assetsType !== 'encoding' && (
                <FormItem label="Destination">
                  <Controller
                    name="destination"
                    rules={{
                      required: {
                        value: true,
                        message: 'Destination is required!'
                      }
                    }}
                    control={control}
                    render={({ field }) => (
                      <div className="flex flex-col gap-2 w-full">
                        <div className="flex flex-row gap-4">
                          <Select
                            isInvalid={Object.keys(formState.errors).indexOf('destination') !== -1}
                            className="w-3/6"
                            options={mezzaninePoDestinationsOptions}
                            placeholder="Please select destination"
                            onChange={(option: any) => field.onChange(option.value)}
                            value={mezzaninePoDestinationsOptions?.find(
                              (option) => field.value === option.value
                            )}
                            readOnly={false}
                          />
                        </div>
                        {formState.errors.destination && (
                          <div className="text-danger-500 mt-1.5 text-sm">
                            {(formState.errors.destination as FieldError).message}
                          </div>
                        )}
                      </div>
                    )}
                  />
                </FormItem>
              )}
              {assetsType === 'encoding' && orderAssetMode !== 'edit' && (
                <FormItem label="Output Format" className="items-start">
                  <Controller
                    name="systems"
                    rules={{
                      required: {
                        value: true,
                        message: 'Output format is required!'
                      }
                    }}
                    control={control}
                    render={({ field }) => (
                      <div className="flex flex-col gap-2 w-full">
                        <div className="flex flex-row gap-4">
                          <SystemPicker
                            orderType={assetsType as string}
                            organization={props.organization}
                            orderGroupId={props.orderGroupId}
                            isInvalid={Object.keys(formState.errors).indexOf('systems') !== -1}
                            systems={systemsOptions}
                            selectedSystems={systems}
                            onSystemChange={(selectedSystems) => {
                              if (selectedSystems.length > 0) {
                                field.onChange(selectedSystems);
                              }
                              selectedSystems && dispatch(setSystems(selectedSystems));
                            }}
                          />
                        </div>
                        {formState.errors.systems && (
                          <div className="text-danger-500 mt-1.5 text-sm">
                            {(formState.errors.systems as FieldError).message}
                          </div>
                        )}
                      </div>
                    )}
                  />
                </FormItem>
              )}
              <FormItem
                label="Audio Languages"
                className="items-start"
                help="Select & order the languages that you want, check badge indicates that this language is available ">
                {audioLanguages && (
                  <Controller
                    name="audioLanguages"
                    rules={{
                      required: {
                        value: assetsType === 'encoding',
                        message: 'Audio languages are required for encodes!'
                      }
                    }}
                    control={control}
                    render={({ field }) => (
                      <div className="flex flex-col gap-2 w-full">
                        <div className="flex flex-row gap-4">
                          <LanguagePicker
                            isInvalid={
                              Object.keys(formState.errors).indexOf('audioLanguages') !== -1
                            }
                            readOnly={false}
                            languages={audioLanguages}
                            selectedLanguages={
                              field.value
                                ? field.value.map((lang) => {
                                    const targetLang = audioLanguages.find(
                                      (item) => item.value === lang
                                    );
                                    if (targetLang) {
                                      return targetLang;
                                    } else {
                                      return audioLanguages[0];
                                    }
                                  })
                                : []
                            }
                            onLanguageChange={(languages: LanguageOption[]) =>
                              field.onChange(languages.map((lang) => lang.value))
                            }
                          />
                        </div>
                        {formState.errors.audioLanguages && (
                          <div className="text-danger-500 mt-1.5 text-sm">
                            {(formState.errors.audioLanguages as FieldError).message}
                          </div>
                        )}
                      </div>
                    )}
                  />
                )}
              </FormItem>
              <FormItem
                label="Subtitle Languages"
                className="items-start"
                help="Select & order the languages that you want, check badge indicates that this language is available ">
                {subtitleLanguages && (
                  <Controller
                    name="subtitleLanguages"
                    control={control}
                    render={({ field }) => (
                      <div className="flex flex-col gap-2 w-full">
                        <div className="flex flex-row gap-4">
                          <LanguagePicker
                            isInvalid={
                              Object.keys(formState.errors).indexOf('subtitleLanguages') !== -1
                            }
                            readOnly={false}
                            languages={subtitleLanguages}
                            selectedLanguages={
                              field.value
                                ? field.value.map((lang) => {
                                    const targetLang = subtitleLanguages.find(
                                      (item) => item.value === lang
                                    );
                                    if (targetLang) {
                                      return targetLang;
                                    } else {
                                      return subtitleLanguages[0];
                                    }
                                  })
                                : []
                            }
                            onLanguageChange={(languages: LanguageOption[]) => {
                              field.onChange(languages.map((lang) => lang.value));
                            }}
                          />
                        </div>
                        {formState.errors.subtitleLanguages && (
                          <div className="text-danger-500 mt-1.5 text-sm">
                            {(formState.errors.subtitleLanguages as FieldError).message}
                          </div>
                        )}
                      </div>
                    )}
                  />
                )}
              </FormItem>
              {assetsType === 'mezzanine' && (
                <FormItem label="Require HD">
                  <Controller
                    name="requireHd"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        defaultChecked={field.value}
                        checked={field.value}
                        onCheckedChange={(value) => field.onChange(value)}
                      />
                    )}
                  />
                </FormItem>
              )}
              {assetsType === 'mezzanine' && (
                <FormItem label="Require 16x9">
                  <Controller
                    name="require16x9"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        defaultChecked={field.value}
                        checked={field.value}
                        onCheckedChange={(value) => field.onChange(value)}
                      />
                    )}
                  />
                </FormItem>
              )}
              {assetsType === 'mezzanine' && (
                <FormItem label="Video">
                  <Controller
                    name="includeVideo"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        defaultChecked={field.value}
                        checked={field.value}
                        onCheckedChange={(value) => field.onChange(value)}
                      />
                    )}
                  />
                </FormItem>
              )}
              {assetsType === 'mezzanine' && (
                <FormItem label="Title Card">
                  <Controller
                    name="titleCard"
                    control={control}
                    render={({ field }) => (
                      <div className="flex flex-col gap-2 w-full">
                        <div className="flex flex-row gap-4">
                          <Select
                            isInvalid={Object.keys(formState.errors).indexOf('titleCard') !== -1}
                            className="w-3/6"
                            options={titleCardOptions}
                            placeholder="Please select title card"
                            isClearable={true}
                            onChange={(option: any) =>
                              option ? field.onChange(option.value) : field.onChange('')
                            }
                            value={titleCardOptions.find((option) => field.value === option.value)}
                          />
                        </div>
                        {formState.errors.titleCard && (
                          <div className="text-danger-500 mt-1.5 text-sm">
                            {(formState.errors.titleCard as FieldError).message}
                          </div>
                        )}
                      </div>
                    )}
                  />
                </FormItem>
              )}
              <div className="flex flex-row-reverse p-4 gap-2 ">
                <Button
                  variant="primary"
                  rounded
                  type="button"
                  onClick={() => handleSubmit(onSubmit)()}>
                  Confirm Configuration
                </Button>
              </div>
            </form>
          </CollapseSection>
          <CollapseSection
            className="border-b border-gray-300"
            id="define-filenames"
            title="Define Filenames"
            active={selectedRows.size > 0}>
            <form>
              <div>
                {systems &&
                  systems.map((system, index) => (
                    <div key={index}>
                      <h3 className="px-7 p-4 font-semibold">{system.label}</h3>
                      <div className="flex flex-row gap-4 bg-gray-50 p-2 text-gray text-sm border-b border-gray-200">
                        <div className="w-7"></div>
                        <div className="w-2/3">Title</div>
                        <div className="flex-1">
                          {assetsType === 'mezzanine' ? 'Filename Prefix' : 'Filename'}
                        </div>
                      </div>
                      {selectedTitles.map((title, titleIndex) => (
                        <div
                          key={titleIndex}
                          className="flex flex-row gap-4 px-3 py-2 items-center text-sm border-b border-gray-200">
                          <div className="items-center justify-center">{iconsMap[title.type]}</div>
                          <div className="w-2/3 text-gray">
                            <TitleLabel catalogTitle={title} />
                          </div>
                          <div className="flex-1">
                            <Controller
                              name={`files.${title._id}-${system.value}`}
                              rules={{
                                required: {
                                  value: assetsType === 'encode',
                                  message:
                                    assetsType === 'mezzanine'
                                      ? 'Filename prefix is required!'
                                      : 'Filename is required!'
                                }
                              }}
                              control={controlFiles}
                              render={({ field }) => (
                                <Input
                                  id={`${title._id}-${system.value}`}
                                  onPaste={(event: ClipboardEvent<HTMLInputElement>) =>
                                    handlePaste(event)
                                  }
                                  rounded
                                  placeholder={
                                    assetsType === 'mezzanine'
                                      ? 'Enter filename prefix'
                                      : 'Enter filename'
                                  }
                                  onChange={field.onChange}
                                  onBlur={field.onBlur}
                                  value={field.value}
                                  error={
                                    formStateFiles.errors.files &&
                                    (formStateFiles.errors.files as any)[
                                      `${title._id}-${system.value}`
                                    ]?.message
                                  }
                                />
                              )}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
              </div>
              <div className="flex flex-row-reverse p-4 gap-2 ">
                <Button
                  variant="primary"
                  rounded
                  type="button"
                  onClick={() => handleSubmitFiles(onFilesSubmit)()}>
                  {orderAssetMode === 'edit' ? 'Save Order Asset' : 'Add Order Asset'}
                </Button>
              </div>
            </form>
          </CollapseSection>
        </Collapse>
      </div>
    </>
  );
}
