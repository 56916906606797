import { generateClient } from 'aws-amplify/api';
import {
  type GraphQLResult,
  GraphqlSubscriptionResult,
  GraphqlSubscriptionMessage
} from '@aws-amplify/api-graphql';
import * as queries from '../../graphql/queries/orders';
import * as mutations from '../../graphql/mutations/orders';
import * as subscriptions from '../../graphql/subscriptions';
import {
  ApproveOrderResponse,
  CreateOrderGroup,
  GetOrderGroupsResponse,
  GetOrderResponse,
  GetOrdersResponse,
  Order,
  OrderGroup,
  RejectOrderResponse,
  RestartOrderDeliveryResponse,
  History,
  UpdateOrderTitleDeliveryInput,
  UpdateOrderInput,
  UpdateOrderStatusOutput
} from './types';
import { Meta, OrderFormPayload } from '../../common/types';
import toast from 'react-hot-toast';
import { print } from 'graphql';

const client = generateClient();

export const getOrders = async (
  variables: {
    page?: number;
    pageSize?: number;
    search?: string;
    orderGroupClientId?: string;
    orderGroupTitle?: string;
    deliveryStatus?: string;
    licenseStatus?: string;
  },
  cb?: (result: { results: Order[]; meta: Meta | null } | undefined) => void
): Promise<void> => {
  const result = (await client.graphql({
    query: print(queries.getOrders),
    variables
  })) as GraphQLResult<GetOrdersResponse>;
  cb && cb(result.data?.listOrders);
};

export const getOrder = async (id: string, cb?: (result: Order | null) => void): Promise<void> => {
  const result = (await client.graphql({
    query: print(queries.getOrder),
    variables: {
      id
    }
  })) as GraphQLResult<GetOrderResponse>;
  cb && cb(result.data?.getOrderById || null);
};

export const approveOrder = async (
  id: string,
  cb?: (result: Order | null) => void
): Promise<void> => {
  try {
    const result = (await client.graphql({
      query: print(mutations.approveOrder),
      variables: {
        id
      }
    })) as GraphQLResult<ApproveOrderResponse>;
    if (result) {
      cb && cb(result.data?.approveOrder || null);
      toast.success('The order was successfully approved!');
    }
  } catch (e: any) {
    console.error(e);
    const errorMessage = e.errors.map((error: any) => error.message);
    toast.error(`Sorry, ${errorMessage}`);
  }
};

export const rejectOrder = async (
  id: string,
  cb?: (result: Order | null) => void
): Promise<void> => {
  try {
    const result = (await client.graphql({
      query: print(mutations.rejectOrder),
      variables: {
        id
      }
    })) as GraphQLResult<RejectOrderResponse>;
    if (result) {
      cb && cb(result.data?.rejectOrder || null);
      toast.success('The order was successfully rejected!');
    }
  } catch (e: any) {
    console.error(e);
    const errorMessage = e.errors.map((error: any) => error.message);
    toast.error(`Sorry, ${errorMessage}`);
  }
};

export const restartOrderDelivery = async (
  id: string,
  cb?: (result: Order | null) => void
): Promise<void> => {
  try {
    const result = (await client.graphql({
      query: print(mutations.restartOrderDelivery),
      variables: {
        id
      }
    })) as GraphQLResult<RestartOrderDeliveryResponse>;
    if (result) {
      cb && cb(result.data?.restartOrderDelivery || null);
      toast.success('Successfully restarted the order delivery!');
    }
  } catch (e: any) {
    console.error(e);
    const errorMessage = e.errors.map((error: any) => error.message);
    toast.error(`Sorry, ${errorMessage}`);
  }
};

export const cancelOrderDelivery = async (
  orderId: string,
  cb?: (result: object | undefined) => void
): Promise<void> => {
  try {
    const result = (await client.graphql({
      query: print(mutations.cancelOrderDelivery),
      variables: { id: orderId }
    })) as GraphQLResult<{ cancelOrderDelivery: string }>;
    if (result) {
      cb && cb(result.data);
      toast.success('Successfully cancelled the order delivery!');
    }
  } catch (error) {
    console.error(error);
    toast.error('Sorry, we cannot cancel this order delivery!');
  }
};

export const bulkApproveOrder = async (ids: string[], cb?: () => void): Promise<void> => {
  try {
    const bulkApprove: string[] = ids.map((id: string, i: number) => {
      return `mutation${i}: approveOrder(_id: "${id}") { _id }`;
    });
    await client.graphql({
      query: `
        mutation approveOrders {
          ${bulkApprove}
        }
      `,
      variables: {}
    });
    cb && cb();
  } catch (e: any) {
    console.error(e);
    const errorMessage = e.errors.map((error: any) => error.message);
    toast.error(`Sorry, ${errorMessage}`);
  }
};

export const bulkRejectOrder = async (ids: string[], cb?: () => void): Promise<void> => {
  try {
    const bulkApprove: string[] = ids.map((id: string, i: number) => {
      return `mutation${i}: rejectOrder(_id: "${id}") { _id }`;
    });
    await client.graphql({
      query: `
        mutation rejectOrders {
          ${bulkApprove}
        }
      `,
      variables: {}
    });
    cb && cb();
  } catch (e: any) {
    console.error(e);
    const errorMessage = e.errors.map((error: any) => error.message);
    toast.error(`Sorry, ${errorMessage}`);
  }
};

export const createOrder = async (
  payload: OrderFormPayload,
  cb?: (result: Order | null) => void,
  errorCb?: (error: string) => void
): Promise<void> => {
  try {
    const result = (await client.graphql({
      query: print(mutations.createOrder),
      variables: payload
    })) as GraphQLResult<Order>;
    cb && cb(result.data || null);
  } catch (e: any) {
    console.error(e);
    const errorMessage = e.errors.map((error: any) => error.message);
    toast.error(`Sorry, we cannot proceed with your order! ${errorMessage}`);
    errorCb && errorCb(errorMessage);
  }
};

export const addOrderGroup = async (
  params: CreateOrderGroup,
  cb?: (result: { createOrderGroup: string } | undefined) => void
): Promise<void> => {
  try {
    const result = (await client.graphql({
      query: print(mutations.createOrderGroup),
      variables: params
    })) as GraphQLResult<{ createOrderGroup: string }>;
    if (result) {
      cb && cb(result.data);
    }
  } catch (error) {
    console.error(error);
    toast.error('Sorry, we cannot add this order group!');
  }
};

export const getOrderGroups = async (
  orgId?: string,
  cb?: (result: OrderGroup[]) => void
): Promise<void> => {
  const result = (await client.graphql({
    query: print(queries.getOrderGroups),
    variables: { orgId }
  })) as GraphQLResult<GetOrderGroupsResponse>;
  if (result.data) {
    cb && cb(result.data.listOrderGroups);
  } else {
    cb && cb([]);
  }
};

export const addOrderMessage = async (
  id: string,
  message: string,
  cb?: (result: object | undefined) => void
): Promise<void> => {
  try {
    const result = (await client.graphql({
      query: print(mutations.addOrderMessage),
      variables: { id, message }
    })) as GraphQLResult<{ addOrderMessage: string }>;
    if (result) {
      cb && cb(result.data);
    }
  } catch (error) {
    console.error(error);
    toast.error('Sorry, we cannot add this order message!');
  }
};

export const onAddOrderMessageSubscription = (
  orderId: string,
  cb?: (result: GraphqlSubscriptionMessage<{ onAddOrderMessage: History }>) => void
) => {
  const result = client.graphql({
    query: print(subscriptions.onAddOrderMessage),
    variables: {
      id: orderId
    }
  }) as GraphqlSubscriptionResult<{ onAddOrderMessage: History }>;
  return result.subscribe({
    next: cb,
    error: (error) => {
      console.error(error);
      toast.error('Sorry, we cannot add this order message!');
    }
  });
};

export const cancelOrderTitleDelivery = async (
  orderId: string,
  deliveryId: string,
  cb?: (result: object | undefined) => void
): Promise<void> => {
  try {
    const result = (await client.graphql({
      query: print(mutations.cancelOrderTitleDelivery),
      variables: { id: orderId, deliveryId: deliveryId }
    })) as GraphQLResult<{ cancelOrderTitleDelivery: string }>;
    if (result) {
      cb && cb(result.data);
      toast.success('Successfully cancelled the order title delivery!');
    }
  } catch (error) {
    console.error(error);
    toast.error('Sorry, we cannot cancel this order title delivery!');
  }
};

export const updateOrderTitleDelivery = async (
  orderId: string,
  deliveryId: string,
  payload: UpdateOrderTitleDeliveryInput,
  cb?: (result: object | undefined) => void
): Promise<void> => {
  try {
    const result = (await client.graphql({
      query: print(mutations.updateOrderTitleDelivery),
      variables: { id: orderId, deliveryId: deliveryId, payload: payload }
    })) as GraphQLResult<{ updateOrderTitleDelivery: Order }>;
    if (result) {
      cb && cb(result.data);
      toast.success('Successfully updated the order title delivery!');
    }
  } catch (error) {
    console.error(error);
    toast.error('Sorry, we cannot update this order title delivery!');
  }
};

export const updateOrder = async (
  orderId: string,
  payload: UpdateOrderInput,
  cb?: (result: object | undefined) => void
): Promise<void> => {
  try {
    const result = (await client.graphql({
      query: print(mutations.updateOrder),
      variables: { id: orderId, payload: payload }
    })) as GraphQLResult<{ updateOrderTitleDelivery: Order }>;
    if (result) {
      cb && cb(result.data);
      toast.success('Successfully updated the order!');
    }
  } catch (error) {
    console.error(error);
    toast.error('Sorry, we cannot update this order!');
  }
};

export interface OnCreateOrderMutations {
  createOrder: Order;
}

export const onCreateOrderSubscription = (
  organizationId: string,
  cb?: (result: GraphqlSubscriptionMessage<OnCreateOrderMutations>) => void
) => {
  const result = client.graphql({
    query: print(subscriptions.onCreateOrder),
    variables: {
      organization: organizationId
    }
  }) as GraphqlSubscriptionResult<OnCreateOrderMutations>;
  return result.subscribe({
    next: cb,
    error: (error) => {
      console.error(error);
      toast.error('Sorry, we cannot subscribe to order creation updates!');
    }
  });
};

export interface OnUpdateOrderMutations {
  approveOrder?: UpdateOrderStatusOutput;
  rejectOrder?: UpdateOrderStatusOutput;
  onUpdateOrder?: UpdateOrderStatusOutput;
  cancelOrderDelivery?: UpdateOrderStatusOutput;
  cancelOrderTitleDelivery?: UpdateOrderStatusOutput;
  updateOrderTitleDelivery?: UpdateOrderStatusOutput;
}

export const onUpdateOrderSubscription = (
  organizationId: string,
  orderId?: string,
  cb?: (result: GraphqlSubscriptionMessage<OnUpdateOrderMutations>) => void
) => {
  const result = client.graphql({
    query: orderId
      ? print(subscriptions.onUpdateOrder)
      : print(subscriptions.onUpdateOrdersForOrganization),
    variables: {
      organization: organizationId,
      id: orderId
    }
  }) as GraphqlSubscriptionResult<OnUpdateOrderMutations>;
  return result.subscribe({
    next: cb,
    error: (error) => {
      console.error(error);
      toast.error('Sorry, we cannot subscribe to updates from this order!');
    }
  });
};
